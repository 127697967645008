<template>
  <el-container class="patroldetail container">
    <el-header class="box add bb">
         <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>巡查管理</el-breadcrumb-item>
       <el-breadcrumb-item  to="/admin/patrol">巡查列表</el-breadcrumb-item>
       <el-breadcrumb-item>巡查详情</el-breadcrumb-item>
    </el-breadcrumb>
        <div><el-button  class="back_btn" size="medium" plain @click="$router.back()">返回</el-button></div>
    </el-header>
    <el-main class="p15 ">
      <el-collapse v-model="activeNames" @change="handleChange">
       <el-collapse-item title="巡查信息" name="1">
        <el-row class="box mt20  f14 c666">
         <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">巡查企业：</span><span class="c333">{{list.company_name}}</span>
        </el-col>
        <el-col :span="11" class="tl mb20" :offset="1" >
          <span class="left">工单类型：</span><span class="c333" v-if="list.patrol_type=='1'">巡查工单</span><span class="c333" v-else-if="list.patrol_type=='2'">双随机工单</span><span class="c333" v-else-if="list.patrol_type=='3'">派发工单</span>
        </el-col>
         <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">工单号：</span><span class="c333" >{{list.work_order}}</span>
        </el-col>
        <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">巡查状态：</span><span class="c333" v-if="list.patrol_state=='1'">待巡查</span><span class="c333" v-else-if="list.patrol_state=='2'">已巡查</span>
        </el-col>
        <el-col :span="11" class="tl mb20" :offset="1" >
          <span class="left">巡查人：</span><span class="c333">{{list.patrol_user_name}}</span>
        </el-col>
        <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">巡查时间：</span><span class="c333">{{list.patrol_time}}</span>
        </el-col>
         <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">派发人：</span><span class="c333">{{list.user_name_add}}</span>
        </el-col>
         <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">派发时间：</span><span class="c333">{{list.add_time}}</span>
        </el-col>
          <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">巡查结果：</span><span class="c333">{{list.patrol_result}}</span>
        </el-col>
        <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">备注：</span><span class="c333">{{list.note}}</span>
        </el-col>
     </el-row>
       </el-collapse-item>
       <!-- <el-collapse-item title="巡查内容" name="2">
      
       </el-collapse-item> -->
       <el-collapse-item title="巡查结果" name="2">
         <el-row class="box mt20  f14 c666">
       
        <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">处理方式：</span><span class="c333">{{list.dispose_name}}</span>
        </el-col>
         <el-col :span="11" class="tl mb20" :offset="1">
          <span class="left">处罚方式：</span><span class="c333">{{list.punish_name	}}</span>
        </el-col>
        
     </el-row>
       </el-collapse-item>
       <el-collapse-item title="巡查图片" class="tl" name="3">
          <div class="disib p15" >
            <el-image v-for="(item,index) in list.accessory_list" :key="index" :src="item.accessory_url" :preview-src-list="[item.accessory_url]" alt="" style="width: 160px;height: 160px"></el-image>
          </div>
       </el-collapse-item>
      </el-collapse>
    </el-main>
 
  </el-container>
</template>
<script>
import axios from '../../../util/api'
import { local,session } from '../../../util/util'
import {imageUrl} from '../../../config/index'
export default {
  data () {
    return {
        imageUrl:imageUrl,
      page: {
       patrol_id:'',
      },
      activeNames: ['1'],
      activeName: '',
       list: [],
      user:'',
      type:'',
    }
  },
   created () {
          this.user = local.get('user');
          this.type=this.$route.query.type;
          if(this.type==1){
            this.page.patrol_id =session.get('params').id?String(session.get('params').id):'';
          }else{
            this.page.patrol_id =session.get('params1').id?String(session.get('params1').id):'';
          }
           
     this.init(this.page)
  },
  methods: {
       init (params) {
      axios.get('/pc/patrol/single', params).then((v) => {
       this.list=v.data.patrol
      })
    },
    handleChange(){},
  }
}
</script>
<style lang="scss" scoped>
.patroldetail{
  overflow-x: hidden;
  .add{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .el-image{width: 160px; height: 160px;margin-right: 10px}
  .back_btn{background:rgba(247,248,249,1);border:1px solid rgba(217,222,225,1);}
  .patroldetail-title{height: 34px;line-height: 34px;border-left: 2px solid #788287}

}

</style>
